import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/headshot.png'


const _hoisted_1 = { class: "myProfile" }
const _hoisted_2 = { class: "socialLink" }
const _hoisted_3 = {
  href: "https://github.com/peter910820",
  target: "_blank",
  rel: "noopener noreferrer"
}
const _hoisted_4 = {
  href: "https://x.com/seaotterMS",
  target: "_blank",
  rel: "noopener noreferrer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"h4\" data-v-1d8408f4><div class=\"headShot\" data-v-1d8408f4><img src=\"" + _imports_0 + "\" alt=\"\" data-v-1d8408f4></div></div><div class=\"myName\" data-v-1d8408f4>SeaotterMS</div><div class=\"aboutMe typer\" data-v-1d8408f4></div><div class=\"littleItem h2\" data-v-1d8408f4><a class=\"waves-effect waves-light btn-large\" href=\"https://github.com/peter910820/seaotterms.com\" data-v-1d8408f4>這是專案本身:pepega:</a></div>", 4)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _createElementVNode("i", {
          class: _normalizeClass([
            'fa-brands',
            'fa-github',
            'fa-2xl',
            { 'fa-spin': _ctx.isHoveredGithub },
          ]),
          onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isHoveredGithub = true)),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHoveredGithub = false)),
          style: {"color":"#000000"}
        }, null, 34)
      ]),
      _createElementVNode("a", _hoisted_4, [
        _createElementVNode("i", {
          class: _normalizeClass([
            'fa-brands',
            'fa-twitter',
            'fa-2xl',
            { 'fa-spin': _ctx.isHoveredTwitter },
          ]),
          onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHoveredTwitter = true)),
          onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isHoveredTwitter = false)),
          style: {"color":"#74c0fc"}
        }, null, 34)
      ])
    ])
  ]))
}