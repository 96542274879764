import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mainStyle" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col l9 m12 s12" }
const _hoisted_4 = { class: "col l3 m12 s12 sticky" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MyProfile = _resolveComponent("MyProfile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MyProfile)
      ])
    ])
  ]))
}