import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-wrapper" }
const _hoisted_2 = { class: "right hide-on-med-and-down" }
const _hoisted_3 = {
  class: "sidenav",
  id: "mobile-demo"
}

import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  setup(__props) {

onMounted(() => {
  const elems = document.querySelectorAll(".sidenav");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-undef
  const instances = M.Sidenav.init(elems);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "brand-logo"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        }),
        _cache[5] || (_cache[5] = _createElementVNode("a", {
          href: "#",
          "data-target": "mobile-demo",
          class: "sidenav-trigger"
        }, [
          _createElementVNode("i", { class: "material-icons" }, "menu")
        ], -1)),
        _createElementVNode("ul", _hoisted_2, [
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("首頁"),
                _createElementVNode("i", { class: "material-icons left" }, "home", -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/create" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("建立"),
                _createElementVNode("i", { class: "material-icons left" }, "edit", -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/login" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("登入"),
                _createElementVNode("i", { class: "material-icons left" }, "login", -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/register" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("註冊"),
                _createElementVNode("i", { class: "material-icons left" }, "how_to_reg", -1)
              ])),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _createElementVNode("ul", _hoisted_3, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("首頁")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/create" }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("建立")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/login" }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("登入")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/register" }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("註冊")
          ])),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})